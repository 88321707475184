import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import SignInSide from "../pages/Login";
import { ProtectedRoute } from "./ProtectedRoute";
import ChangePassword from "../pages/ChangePassword";
import ForgotPassword from "../pages/ForgotPassword";
import Dashboard from "../pages/Dashboard";
import Qualification from "../pages/Qualification";
import Draft from "../pages/Draft";
import Release from "../pages/Release";
import GenerateQualification from "../pages/GenerateQualification";

export const Navigation = () => {
  return (
    <BrowserRouter>
      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Routes>
        <Route path="/login" element={<SignInSide />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/qualification/:leadId"
          element={
            <ProtectedRoute>
              <Qualification />
            </ProtectedRoute>
          }
        />
        <Route
          path="/leads/draft"
          element={
            <ProtectedRoute>
              <Draft />
            </ProtectedRoute>
          }
        />
        <Route
          path="/leads/create-qualification"
          element={
            <ProtectedRoute>
              <GenerateQualification />
            </ProtectedRoute>
          }
        />
        {/*
          <Route
          path="/leads/release"
          element={
            <ProtectedRoute>
              <Release />
            </ProtectedRoute>
          }
        />
         */}
        <Route path="*" element={<Navigate to={"/"} replace={true} />} />
      </Routes>
    </BrowserRouter>
  );
};
