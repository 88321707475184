import client from "./client";
import { getAuthHeader } from "./authHeader";
import { IApplicant } from "./applicants";
import * as qs from "qs";

type LeadStatus = "new" | "qualified" | "contacted";
interface LeadStatusFilter {
  status: LeadStatus[];
}

export const getLeads = async (filter: LeadStatusFilter): Promise<ILead[]> => {
  const query = qs.stringify({
    populate: ["campaign", "campaign.company"],
    filters: {
      status: {
        $contains: filter.status,
      },
    },
    pagination: {
      pageSize: 300,
    },
  });
  const request = await client.get(`/leads?${query}`, {
    headers: getAuthHeader(),
  });
  // @ts-ignore
  const data = request.data.data.map((item) => {
    const idStr = item.id.toString();
    return { ...item.attributes, id: idStr };
  });
  return data;
};

export const getLead = async (leadId: string): Promise<ILead> => {
  const request = await client.get(`/leads/${leadId}`, {
    params: {
      populate: ["applicant", "campaign", "campaign.company"],
    },
    headers: getAuthHeader(),
  });
  const idStr = request.data.data.id.toString();
  return { ...request.data.data.attributes, id: idStr };
};

export const releaseLead = async (leadId: string): Promise<void> => {
  await client.post(
    `/leads/release/${leadId}`,
    {},
    {
      headers: getAuthHeader(),
    }
  );
  return;
};

export const dismissLead = async (leadId: string): Promise<void> => {
  await client.post(
    `/leads/dismiss/${leadId}`,
    {},
    {
      headers: getAuthHeader(),
    }
  );
  return;
};

export const uploadDocument = async (leadId: string, file: File): Promise<void> => {
  if (!leadId || !file) {
    throw new Error("Lead ID and file are required.");
  }

  const formData = new FormData();
  formData.append("files", file);

  try {
    // Upload the file to the backend
    const response = await client.post(
      `/leads/upload/${leadId}`,
      formData,
      {
        headers: {
          ...getAuthHeader(),
          "Content-Type": "multipart/form-data",
        }
      }
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Failed to upload document.");
    }
  } catch (error) {
    console.error("Error uploading document:", error);
    throw error;
  }
};

export const createQualification = async (file: File, campaign: string): Promise<IQualification> => {
  if (!campaign || !file) {
    throw new Error("Lead ID and file are required.");
  }

  const formData = new FormData();
  formData.append("files", file);
  formData.append("campaign", campaign);

  try {
    // Upload the file to the backend
    const response = await client.post(
      `/leads/ai/generate-qualification`,
      formData,
      {
        headers: {
          ...getAuthHeader(),
          "Content-Type": "multipart/form-data",
        }
      }
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Failed to upload document.");
    }
  } catch (error) {
    console.error("Error uploading document:", error);
    throw error;
  }
};


export const updateLeadStatus = async (leadId: string, status: LeadStatus): Promise<void> => {
  await client.put(
    `/leads/${leadId}`,
    {
      data: {
        "status": status
      }
    },
    {
      headers: getAuthHeader(),
    }
  );
  return;
};

export const isDuplicat = async (leadId: string): Promise<IDuplicateResponse> => {
  const response = await client.get(
    `/leads/duplicate/${leadId}`,
    {
      headers: getAuthHeader(),
    }
  )
  return response.data;
  
}

export interface ILead {
  readonly id: string;
  readonly completedAt: string;
  readonly status: string;
  readonly response: IResponse;
  readonly campaign: ICampaign;
  readonly applicant: {
    data: {
      id: string;
      attributes: Omit<
        IApplicant,
        "id" | "comments" | "attachments" | "campaign"
      >;
    };
  };
}

export interface IQualification {
  readonly type: string;
  readonly text: {
    value: string,
  }
}

export interface ICampaign {
  data: {
    id: string;
    attributes: {
      name: string;
      place: string | undefined;
      language: string | undefined;
      tasks: string | undefined;
      requierements: string | undefined;
      company: ICompany;
    }
  };
}

export interface ICompany {
  data: {
    id: string;
    attributes: {
        name: string;
        headquarter: string | undefined;
        description: string | undefined;
      };
  
    };
};

export interface IResponse {
  [key: string]: {
    title: string;
    value: string;
  };
}

export interface IDuplicateResponse {
  readonly duplicate: boolean;
  readonly applicants: IDuplicateApplicants[];
}

export interface IDuplicateApplicants {
  readonly createdAt: string;
  readonly campaign: string | undefined;
  readonly company: string | undefined;
  readonly status: string;
}