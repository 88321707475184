import * as React from "react";
import { useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createQualification } from "../infrastructure/api/leads";
import useToken from "../hooks/useToken";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function GenerateQualification() {
  const [campaign, setCampaign] = useState("");
  const [qualification, setQualification] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const { removeToken } = useToken();
  const navigate = useNavigate();
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    text: "Erfolgreich geändert!",
  });

  const handleRequestError = (e: unknown) => {
    setLoading(false);
    // @ts-ignore
    if (e.response.status === 401 || e.response.status === 403) {
      removeToken();
      navigate("/login", { replace: true });
    }
    // @ts-ignore
    if (e.response.status === 400 || e.response.status === 500) {
      setSnack({
        open: true,
        severity: "error",
        text: "Etwas ist schiefgelaufen, versuchen Sie es später erneut.",
      });
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setSelectedFile(files[0]);
    }
  };

  const handleSubmit = async () => {
    if (campaign && selectedFile) {
      setLoading(true);
      try {
        const response = await createQualification(selectedFile, campaign);
        setQualification(response.text.value);
        setSnack({
          open: true,
          severity: "success",
          text: "Der Text wurde erfolgreich erstellt",
        });
        // Zustand zurücksetzen
        setCampaign("");
        setSelectedFile(null);
        // Dateieingabe zurücksetzen
        (document.getElementById("file-input") as HTMLInputElement).value = "";
      } catch (e) {
        handleRequestError(e);
      } finally {
        setLoading(false);
      }
    } else {
      setSnack({
        open: true,
        severity: "error",
        text: "Bitte füllen Sie das Kampagnenfeld aus und wählen Sie eine Datei aus.",
      });
    }
  };

  const handleCopy = () => {
    if (qualification) {
      navigator.clipboard.writeText(qualification);
      setSnack({
        open: true,
        severity: "success",
        text: "Text in die Zwischenablage kopiert!",
      });
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography fontSize={"2rem"} fontWeight={700}>
            Text zur Qualifizierung erstellen
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Kampagne"
            value={campaign}
            onChange={(e) => setCampaign(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <input
            id="file-input"
            type="file"
            onChange={handleFileChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!campaign || !selectedFile || loading}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            {loading ? "Lädt..." : "Qualifizierung erstellen"}
          </Button>
        </Grid>
        {qualification && (
          <>
            <Grid item xs={12}>
              <Typography fontSize={"1rem"} fontWeight={400}>
                {qualification}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCopy}
                startIcon={<ContentCopyIcon />}
              >
                Text kopieren
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          severity={snack.severity as "success" | "error" | "warning" | "info"}
          onClose={() => setSnack({ ...snack, open: false })}
        >
          {snack.text}
        </Alert>
      </Snackbar>
    </Box>
  );
}
