import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Callout from './Callout';
import { IDuplicateApplicants, isDuplicat } from '../../infrastructure/api/leads';

interface ILeadExistsProps {
    readonly leadId: string | undefined
}

export const LeadExistsCallout = ({leadId }: ILeadExistsProps) => {
  const [isDuplicate, setIsDuplicate] = useState(false)
  const [duplicateApplicant, setDuplicateApplicant] = useState<IDuplicateApplicants[]>([])

  useEffect(() => {
    const getData = async () => {
      if (leadId) {
        const response = await isDuplicat(leadId)
        setIsDuplicate(response.duplicate)
        setDuplicateApplicant(response.applicants)
      }
      
    }
    getData();
  }, [leadId])

  if (isDuplicate) {
    return (
      <Grid item>
            <Callout applicants={duplicateApplicant}/>
      </Grid>
    );
  }
  return <></>
}