import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { IDuplicateApplicants } from '../../infrastructure/api/leads';

interface ICalloutProps {
    readonly applicants: IDuplicateApplicants[]
}

export const Callout = ({applicants }: ICalloutProps) => {
  return (
    <Card sx={{ bgcolor: 'rgba(255, 249, 235, 0.5)', mt: 1, mb: 1, display: "flex", border: 1, borderColor: "#FFECA1", boxShadow: 0 }}>
      <CardContent sx={{display: "flex", alignSelf: "center", gap: 2}}>
        <WarningIcon sx={{ color: "#D18E00"}} />
        <Grid>
          {RenderDuplicateInfos(applicants)}
        </Grid>
      </CardContent>
    </Card>
  );
}

const RenderDuplicateInfos = (applicants: IDuplicateApplicants[]): JSX.Element[] => {
  const result: JSX.Element[] = []
  applicants.map((item) => {
    if (item.company) {
      result.push(
        <Typography>Hat sich bereits am {TransformDate(item.createdAt)} als {item.campaign} bei {item.company} beworben. Der aktuelle Stand {item.status}</Typography>
      )
    } else {
      result.push(
        <Typography>Hat sich bereits am {TransformDate(item.createdAt)} beworben. Wurde nicht freigegeben.</Typography>
      )
    }
  })
  return result
}

const TransformDate = (createdAt: string) => {
  return new Date(createdAt).toLocaleString("de-De", {
    month: "short",
    day: "numeric",
    year: "numeric",
  })
}

export default Callout;
